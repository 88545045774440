<template>
  <div class="card-cart">
    <div class="card-cart__img it" @click="productDescription">
      <img :src="order.thumb" alt="order-image.png" />
    </div>

    <div class="card-cart__about it">
      <p class="article">Артикул: {{ order.sku }}</p>
      <h3>{{ order.name }}</h3>
      <p v-html="order.meta_description"></p>
      <p>{{ order.weight }} шт. в упаковке</p>
    </div>

    <div class="card-cart__qty">
      <p>{{ order.quantity }} уп.</p>
    </div>

    <div class="card-cart__control it">
      <div class="card-cart__price">
        <h3>{{ Math.floor(order.price_raw * order.quantity) + order.currency }}</h3>
      </div>
      <div class="card-cart__remove">
        <img
          class="remove"
          src="@/assets/img/cancel.png"
          alt=""
          @click="removeCart(order.key)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    order: {
      type: Object,
      reqired: true,
    },
  },
  methods: {
    ...mapActions("cart", ["removeCart"]),

    productDescription() {
      this.$router.push("/products/" + this.order.product_id);
    },
  },
};
</script>

<style scoped>
h3 {
  margin: 0;
  padding: 0;
}

.card-cart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* margin-bottom: 20px; */
  flex-wrap: nowrap;
}

.card-cart div {
  flex-wrap: nowrap;
}

.it {
  flex: 1 1 20%;
}

.card-cart__img {
  cursor: pointer;
  margin-right: 35px;
}
.card-cart__img img {
  width: 150px;
  height: auto;
}

.card-cart p {
  font-weight: 300;
  font-size: 10px;
  line-height: 154.5%;
  color: #7a7a7a;
}
.card-cart h3 {
  font-weight: 600;
  font-size: 18px;
  /* line-height: 22px; */
  text-transform: uppercase;
  color: #000000;
  /* padding-top: 15px; */
}
/* .card-cart__about {
  min-width: 170px;
  max-width: 170px;
} */
.card-cart__qty {
  display: flex;
  align-items: center;
}
.card-cart__qty p {
  font-size: 16px;
  font-weight: 600;
  padding: 0 20px;
  margin: 0;
}

.card-cart__control {
  display: flex;
  align-items: center;
}

.card-cart__price {
  margin-right: 10px;
}

.card-cart__remove img {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

@media (max-width: 550px) {
  .card-cart {
    flex-wrap: wrap;
  }
  .it {
    flex: 1 1 40%;
  }
  .card-cart__qty {
    justify-content: center;
  }
  .card-cart__control,
  .card-cart__price {
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
}
</style>
