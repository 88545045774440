<template>
  <section class="form">
    <div class="container">
      <p class="form__back">
        <router-link to="/catalog">Вернуться к покупкам</router-link>
      </p>
      <h2 class="title">Оформление заказа</h2>
      <div class="form__body">
        <div class="form__forms forms">
          <div class="forms__contact mb-5">
            <h3>Контактные данные:</h3>
            <!-- :value="isAuth.firstname ? fullname : ''" -->
            <!-- :value="isAuth.telephone ? isAuth.telephone : ''" -->
            <!-- :value="isAuth.email ? isAuth.email : ''" -->
            <input
              type="text"
              name="name"
              placeholder="Имя и фамилия"
              v-model="delivery.fullname"
              @focus="clearError"
              @blur="validateName"
              required
            />
            <div class="form__wrapper">
              <input
                type="text"
                name="phone"
                placeholder="+7 777 123 45 67"
                v-model="delivery.phone"
                @focus="clearError"
                @blur="validateField"
                required
              />
              <input
                type="email"
                name="email"
                placeholder="E-MAIL"
                v-model="delivery.email"
                @focus="clearError"
                @blur="validateField($event, 'email')"
                required
              />
            </div>
          </div>
          <div class="forms__delivery mb-5">
            <h3>Доставка:</h3>

            <div class="form__wrapper">
              <select
                id="country"
                name="country"
                v-model="delivery.country"
                required
              >
                <option value="null" disabled :selected="!delivery.country">
                  Выберите страну
                </option>
                <option value="109">Казахстан</option>
                <option value="176">Российская Федерация</option>
                <option value="115">Киргизия (Кыргызстан)</option>
                <option value="226">Узбекистан</option>
              </select>
              <input
                type="text"
                name="city"
                v-model="delivery.city"
                @focus="clearError"
                @blur="validateField($event, 'city')"
                placeholder="Город"
              />
            </div>

            <!-- <select
              id="zone"
              name="zone"
              v-model="delivery.zoneChecked"
              v-if="!delivery.zoneChecked"
            >
              <option value="0" disabled :selected="!delivery.zoneChecked">
                Выберите вашу область
              </option>
              <option
                v-for="zone in delivery.zones"
                :key="zone.zone_id"
                :value="zone.zone_id"
              >
                {{ zone.name }}
              </option>
            </select> -->
            <div class="form__wrapper">
              <input
                type="text"
                name="street"
                placeholder="Улица"
                v-model="delivery.street"
                @focus="clearError"
                @blur="validateField($event, 'street')"
                required
              />
            </div>
            <div class="form__wrapper">
              <!-- <input type="text" name="corpus" placeholder="Корпус" disabled />
              <input type="text" name="block" placeholder="Подъезд" disabled />
              <input type="text" name="floor" placeholder="Этаж" disabled /> -->
              <input
                type="text"
                name="house"
                placeholder="Дом"
                v-model="delivery.building"
                @focus="clearError"
                @blur="validateField($event, 'building')"
                required
              />
              <input
                type="text"
                name="flat"
                placeholder="Квартира"
                v-model="delivery.apartment"
                @focus="clearError"
                @blur="validateField($event, 'appartment')"
                required
              />
            </div>
          </div>

          <!-- <div class="forms__date">
            <h3>Дата и время:</h3>

            <div class="form__wrapper">
              <input type="text" name="data" placeholder="22.05.2021" />
              <input type="text" name="time" placeholder="с 09:00 до 18:00" />
            </div>
          </div> -->
          <!-- <button @click="payOrder('kaspiPay')">Оплата Kaspi</button> -->
          <!-- <button class="kaspi-button" @click="payOrder('kaspiPay')">Оплатить с <img src="../assets/img/kaspi-icon.svg"><b>Kaspi.kz</b></button>e -->
          <form
            action="https://kaspi.kz/online"
            method="post"
            id="kaspikz-form"
            class="payment-form"
          >
            <input type="hidden" id="tran" name="TranId" />
            <input type="hidden" id="order" name="OrderId" />
            <input type="hidden" id="amount" name="Amount" />
            <input type="hidden" name="Service" value="RizaHerb" />
            <input
              type="hidden"
              name="returnUrl"
              value="https://rizaherb.kz/home"
            />
            <input type="hidden" name="Signature" value="" />
            <button class="btn" style="display: none" type="submit">
              Оплатить
            </button>
          </form>
          <button @click="payOrder('cloudPay')">Оплата по карте</button>
        </div>

        <div class="form__zakaz" v-if="getCart.length">
          <h3>Ваш заказ:</h3>
          <div class="form__zakaz-block">
            <OrderItem
              v-for="order in getCart"
              :key="order.id"
              :order="order"
            />
          </div>
          <div class="zakaz__text">
            <div class="zakaz__sum">
              <p>Сумма:</p>
              <p>{{ Math.floor(getTotal) + " " + currency }}</p>
            </div>
            <div class="zakaz__sum">
              <!-- <p>Доставка:</p>
              <p>Бесплатно</p> -->
              <!-- <p>{{ getTotal > 5000 ? "Бесплатно" : "5000 тг" }}</p> -->
            </div>
            <div class="zakaz__sum">
              <p>Итого:</p>
              <p>
                <span>{{ Math.floor(getTotal) + " " + currency }}</span>
              </p>
            </div>
            <div class="zakaz__sum" v-if="getDiscountTotal">
              <p>Итого со скидкой:</p>
              <p>
                <span
                  ><b>{{ Math.floor(getDiscountTotal) + currency }}</b></span
                >
              </p>
            </div>
            <!-- <div class="zakaz__sum" v-if="getTotal < 5000">
              <p>
                Для бесплатной доставки приобретите <br />
                еще товары на сумму:
              </p>
              <p><span>1 500 тг</span></p>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <modal-message v-if="kaspi">
      <div class="close-modal" @click="kaspi = false">&times;</div>
      <h2>Kaspi Payment</h2>
      <h3>Сумма к оплате</h3>
      <h3>{{ (this.getDiscountTotal || this.getTotal) + currency }}</h3>
    </modal-message>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import OrderItem from "@/components/order/OrderItem.vue";
import PublicAPI from "@/api/PublicAPI";

export default {
  data() {
    return {
      delivery: {
        fullname: "",
        phone: "",
        email: "",
        country: null,
        city: "",
        street: "",
        building: "",
        apartment: "",
      },
      kaspi: null,
    };
  },
  components: {
    OrderItem,
  },
  computed: {
    ...mapGetters(["getGeo"]),
    ...mapGetters("cart", ["getCart", "getTotal", "getDiscountTotal"]),
    ...mapGetters("auth", ["isAuthenticated", "getUserData"]),
    isAuth() {
      return this.getUserData;
    },
    fullName() {
      return `${this.isAuth.firstname} ${this.isAuth.lastname}`;
    },
    firstname() {
      return this.delivery.fullname.split(" ")[0];
    },
    lastname() {
      return this.delivery.fullname.split(" ")[1];
    },

    discount() {
      return this.getTotal - (this.getTotal / 100) * 10;
    },
    address() {
      return this.isAuth && this.isAuth.address;
    },

    apartment() {
      return this.isAuth && this.address.address_2.split("/-/")[1] || this.isAuth && this.address.address_2.split("/")[1];
    },

    building() {
      return this.isAuth && this.address.address_2.split("/-/")[0] || this.isAuth && this.address.address_2.split("/")[0];
    },
    currency() {
      return this.getCart[0].currency;
    },
  },
  methods: {
    ...mapActions("cart", ["updateCart"]),
    // TODO Bring this methods to cart store
    // Order_id need to keep for order history in UserPersonal

    async payOrder(method) {
      if (!this.isAuthenticated) {
        await PublicAPI.guestRequest("rest/guest/guest", {
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.delivery.email,
          telephone: this.delivery.phone,
          address_1:
            "Улица:" +
            this.delivery.street +
            " ," +
            "Дом:" +
            this.delivery.building +
            " ," +
            "Квартира:" +
            this.delivery.apartment,
          city: this.delivery.city,
          country_id: this.delivery.country,
        });
      }

      if (!this.isValidateForm()) {
        return
      }

      const order_id = await PublicAPI.simpleConfirm(
        "rest/simple_confirm/confirm"
      );

      const orderID = order_id.order_id;
      // console.log(orderID)
      // PublicAPI.successPay("rest/simple_confirm/confirm")
      // KASPI

      if (method === "kaspiPay") {
        const form = document.querySelector("#kaspikz-form");
        const tranIDField = document.querySelector("#tran");
        const orderIDField = document.querySelector("#order");
        const amountIDField = document.querySelector("#amount");

        tranIDField.value = orderID;
        orderIDField.value = orderID;
        amountIDField.value = (this.getDiscountTotal || this.getTotal)*100;
        PublicAPI.successPay("rest/simple_confirm/confirm");
        form.submit();
      }


      if (method === "cloudPay") {
        const widget = new window.cp.CloudPayments();
        widget.vc = this;

        widget.pay(
          "auth", // или 'charge'
          {
            //options
            publicId: "pk_a2ba67c41c3d39714e9e1f7aac648", //id из личного кабинета
            description: "Оплата товаров в rizaherb.kz", //назначение
            amount: this.getDiscountTotal || this.getTotal, //сумма
            currency: "KZT", //валюта
            accountId: this.isAuth.email || "", //идентификатор плательщика (необязательно)
            invoiceId: orderID || "", //номер заказа  (необязательно)
            skin: "classic", //дизайн виджета (необязательно)
            data: {
              myProp: "myProp value",
            },
          },
          {
            onSuccess: function () {
              // success
              //действие при успешной оплате
              console.log("SUCCESS");
              PublicAPI.successPay("rest/simple_confirm/confirm");
              widget.vc.updateCart();
              widget.vc.$router.replace("/personal");
            },
            onFail: function () {
              // fail
              //действие при неуспешной оплате
              console.log("FAIL");
            },
            onComplete: function () {
              //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
              //например вызов вашей аналитики Facebook Pixel
              console.log("COMPLETE");
            },
          }
        );
      }
    },

    sendForm(event) {
      console.log(event);
    },

    async modalKaspi() {
      this.kaspi = true;
      console.log(await PublicAPI.isCustomerLogged());
    },
    closeModal() {
      this.kaspi = false;
    },

    // async sendToKaspi() {

    // },

    validateName(e) {
      if (e.target.value.split(" ").length < 2) {
        e.target.style.color = "red";
        return (e.target.value = "Имя и Фамилия, обязательна, через пробел");
      }
      if (e.target.value.split(" ").length >= 2) {
        e.target.style.color = "black";
      }
    },
    isValidateForm() {
      if (
        !this.delivery.fullname ||
        !this.delivery.phone ||
        !this.delivery.email
      ) {
        return false
      } else {
        return true
      }
    },
    validateField(event) {
      const $target = event.target;
      let value = $target.value;

      if (!value) {
        $target.classList.add("attention");
        // $target.style.color = "red";
        // return $target.value = "Заполните это поле"
      }
    },
    clearError(e) {
      e.target.value = "";
      e.target.style.color = "black";
      e.target.classList.remove("attention");
    },

    // async getZoneId() {
    //   const respZone = await this.$axios(
    //     `https://back.rizaherb.kz/index.php?route=feed/rest_api/countries&id=${this.delivery.country}`
    //   );
    //   const {
    //     data: {
    //       data: { zone },
    //     },
    //   } = respZone;
    //   this.delivery.zones = zone;
    // },
    defineCountry() {
      switch (this.getGeo) {
        case "UA":
          return (this.delivery.country = "109");
        case "KZ":
          return (this.delivery.country = "109");
        case "KG":
          return (this.delivery.country = "115");
        case "UZ":
          return (this.delivery.country = "226");
        default:
          return (this.delivery.country = "176");
      }
    },
  },
  watch: {
    getCart(cart) {
      if (cart.length === 0) {
        this.$router.replace("/catalog");
      }
    },
  },
  mounted() {
    if (this.isAuthenticated) {
      this.delivery = {
        fullname: this.fullName,
        phone: this.isAuth.telephone,
        email: this.isAuth.email,
        country: this.address.country_id,
        // zoneChecked: this.address.zone_id,
        // zone: this.address.zone,
        city: this.address.city,
        street: this.address.address_1,
        building: this.building,
        apartment: this.apartment,
      };
    }
    this.defineCountry();

    let cloudpayments = document.createElement("script");
    cloudpayments.setAttribute(
      "src",
      "https://widget.cloudpayments.ru/bundles/cloudpayments"
    );
    document.head.appendChild(cloudpayments);
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@300;400;500;600;700&display=swap");

.container {
  max-width: 1068px;
  margin: 0 auto;
  padding: 0 10px;
}

.form__back {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  padding-bottom: 36px;
}

.form__body {
  max-width: 100%;
}
.form h2 {
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-transform: uppercase;
}
.form .title {
  padding-bottom: 36px;
}
.form__body {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  column-gap: 20px;
  row-gap: 20px;
}
.form h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #606060;
  padding-bottom: 15px;
}
.form .forms {
  max-width: 420px;
}
.form .form__zakaz {
  max-width: 550px;
}
.form .forms,
.form .form__zakaz {
  flex: 1 1 50%;
}

.form .forms input {
  padding: 10px 0 10px 15px;
  border: 1px solid #c4c4c4;
  margin-bottom: 15px;
  /* margin-right: 15px; */
}
.form .forms__contact input[name="name"] {
  width: 100%;
}
.form__wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  column-gap: 10px;
}
.form__wrapper input[name="phone"],
.form__wrapper input[name="email"],
.form .forms__delivery select,
.form__wrapper input[name="city"] {
  width: 50%;
}

.form .forms__deliver {
  width: 100%;
}

.form .forms__delivery select {
  /* input[name="country"] */
  padding: 10px 0 10px 15px;
  border: 1px solid #c4c4c4;
  background-color: #fff;
  margin-bottom: 15px;
}
.form .forms__delivery select[name="zone"] {
  width: 100%;
}

.form .forms__delivery input[name="street"] {
  width: 100%;
}

.form .forms__delivery input[name="house"],
.form .forms__delivery input[name="flat"] {
  width: 45%;
}

.form .forms button {
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  padding: 30px 100px;
  background-color: #75b051;
  border-radius: 5px;
  display: inline-block;
  margin-top: 60px;
  width: 100%;
  max-width: 430px;
}
.form__zakaz .form__zakaz-block {
  border: 1px solid #c4c4c4;
  padding: 11px 15px;
}
/* Oreder Item */

/* End of Order Item*/
.form__zakaz .zakaz__text {
  padding-top: 35px;
}
.form__zakaz .zakaz__text p {
  font-size: 18px;
  line-height: 19px;
  color: #606060;
}
.form__zakaz .zakaz__text span {
  font-weight: 700;
}
.form__zakaz .zakaz__text .zakaz__sum {
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
}

.form__zakaz .zakaz__text .zakaz__sum span {
  font-size: 20;
  font-weight: 500;
  font-style: normal;
}

.form__zakaz .card-cart__like > img {
  cursor: pointer;
}

input.attention {
  border: 1px solid red !important;
}

.kaspi-button {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 30px 20px !important;
  background-color: #F14635 !important;
}
.kaspi-button img {
  margin: 0 10px;
}

@media (max-width: 768px) {
  .form .forms button {
    margin: 30px 0;
  }
}
@media (max-width: 425px) {
  .form .forms button {
    line-height: 20px;
    padding: 15px 80px;
    margin: 20px 0;
  }
  .form__wrapper {
    flex-wrap: wrap;
  }
  .form__wrapper input[name="phone"],
  .form__wrapper input[name="email"],
  .form .forms__delivery select,
  .form__wrapper input[name="city"] {
    width: 100%;
  }
}
</style>
