import axios from 'axios';
// import store from 'vuex'


const defaultHeaders = () => {
  const SESS_ID = window.$cookies.get('SESSID');

  const DEFAULT_HEADERS = {
    "x-oc-merchant-id": process.env.VUE_APP_MERCHANT_ID,
    "x-oc-merchant-language": "ru-ru",
    "x-oc-session": SESS_ID,
  }
  return DEFAULT_HEADERS
}

export default class PublicAPI {
  static async http({ url, headers, body, method }) {
    const endpoint = `${process.env.VUE_APP_API_ENDPOINT}${url}`;
    const config = {
      url: endpoint,
      method,
      headers: {
        ...defaultHeaders(),

        // Other parameters of header
        ...headers,
      },
      data: {
        ...body,
      },
    };
    let response;
    try {
      //*ASK response already exist data from promise, may be better use destructure? or at least assign response = response.data.data,
      // for finilly result
      response = await axios(config);
      response = response.data.data;
    } catch (err) {
      // I need a data of response for trace out errors from server
      const {data: { error }} = err.response
      alert("Ошибка ввода данных", error);
      // response = {
      //   status: 'error',
      //   error: err.message,
      //   data: error
      // }
    }
    return response;
  }

  // General methods
  static get(url) {
    return PublicAPI.http({ url, method: 'get' })
  }

  static post(url, body) {
    return PublicAPI.http({ url, body, method: 'post' })
  }

  static put(url, body) {
    return PublicAPI.http({ url, body, method: 'put' })
  }

  static delete(url) {
    return PublicAPI.http({ url, method: 'delete' })
  }

  /* User methods in components */

  // User account page requests:

  static getHistoryOrders(url) {
    return PublicAPI.get(url)
  }

  static getPromoBenefits(url) {
    return PublicAPI.get(url)
  } 

  static showOrderDetail(url) {
    return PublicAPI.get(url)
  }

  static changePasswordField(url, data) {
    return PublicAPI.put(url, data)
  }

  static sendAmbassadorCash(url, data) {
    return PublicAPI.post(url, data)
  } 


  // Cart requests:
  static getCartInfo(url) {
    return PublicAPI.get(url)
  }
  static setCartChanges(url, data) {
    return PublicAPI.put(url, data)
  }

  // Checkout requests:

  static guestRequest(url, data) {
    return PublicAPI.post(url, data)
  }

  static simpleConfirm(url) {
    return PublicAPI.post(url)
  }

  static successPay(url) {
    return PublicAPI.put(url)
  }

  static isCustomerLogged() {
    return PublicAPI.get('feed/rest_api/customerlogged')
  }

  // OneClickModal request:

  static askKet(url, data) {
    return PublicAPI.post(url, data)
  }

  // KASPI payment:
  static getTransactionId(url) {
    return PublicAPI.get(url)
  }

  static sendKaspiForm(url, data) {
    return PublicAPI.post(url, data)
  }

  // Blog Data:
  static getBlogData(url) {
    return PublicAPI.get(url)
  }
}